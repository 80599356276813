<template>
  <div class="search">
    <div class="search-box">
      <el-button type="primary" size="medium" @click="getList">查询</el-button>
      <el-button type="primary" size="medium" @click="addDialog = true;form={};isEdit=false">添加</el-button>
    </div>
  </div>
  <el-table :data="tableData">
    <el-table-column prop="type" label="ID"></el-table-column>
    <el-table-column prop="name" label="分类"></el-table-column>
    <el-table-column prop="tmp" label="模板">
      <template #default="scope">
        {{getTmpTitle(scope.row.tmp)}}
      </template>
    </el-table-column>
    <el-table-column prop="weight" label="权重"></el-table-column>
    <el-table-column prop="disable" label="状态">
      <template #default="scope">
        <el-switch :active-value="1" v-model="scope.row.disable" disabled :inactive-value="0"></el-switch>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="150px">
      <template #default="scope">
        <el-button-group>
          <el-button @click="showEditDialog(scope.row,scope.$index)" size="small">编辑</el-button>
          <el-button @click="delApp(scope.row.type,scope.$index)" size="small">删除</el-button>
        </el-button-group>
      </template>
    </el-table-column>
  </el-table>
  <page :pager="pager" @query="getList()" />
  <el-dialog v-model="addDialog" :title="isEdit?'编辑':'添加'">
    <el-form label-width="80px">
      <el-form-item label="分类">
        <el-input v-model="form.name" placeholder="请输入分类名字"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-switch :active-value="1" :inactive-value="0" v-model="form.disable" ></el-switch>
      </el-form-item>
      <el-form-item label="模板">
        <el-select v-model="form.tmp">
          <el-option v-for="tmp of tmps" :key="tmp" :label="tmp.label" :value="tmp.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="权重">
        <el-input v-model="form.weight" type="number" placeholder="请输入权重"></el-input>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="onAddSubmit">{{isEdit?"编辑":"添加"}}</el-button> <el-button @click="addDialog = false">关闭</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import httpClient from "@/config/httpClient";
import page from "@/components/page.vue";
export default {
  name: "ad_community",
  components: {
    page
  },
  data() {
    return {
      addDialog:false,
      isEdit:false,
      tableData:[],
      pager:{page:1,rows:20,total:0},
      search:{
      },
      form:{
        type:"",name:"",disable:0,weight:0,tmp:0
      },
      curRowIndex:0,
      tmps:[
        {value:0,label:"小图标"},
        {value:1,label:"大图标"}
      ]
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getTmpTitle(id){
      for(const tmp of this.tmps){
        if (tmp.value == id){
          return tmp.label
        }
      }
      return "未知模板:"+id
    },
    showEditDialog(row,index){
      this.curRowIndex = index
      // this.editForm = {
      this.form = {
        type:row.type,
        name:row.name,
        disable:row.disable,
        weight:row.weight,
        tmp:row.tmp,
      }
      this.isEdit = true
      this.addDialog = true
    },
    //获取广告
    getList() {
      let param = this.search
      param.page = this.pager.page -1
      param.row = this.pager.rows
      httpClient("getTypeList").post(param).then((res) => {
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    onAddSubmit(){
      if (this.isEdit){
        this.form['status'] = this.form.disable
        httpClient("xfUpdateType").post(this.form).then((res) => {
          if (res.code == 0){
            this.isEdit = false
            this.addDialog = false
            for(var key in this.form){
              this.tableData[this.curRowIndex][key] = this.form[key]
            }
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      }else{
        httpClient("xfAddType").post(this.form).then((res) => {
          if (res.code == 0){
            this.addDialog = false
            this.getList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      }

    },
    delApp(id,index){
      this.$confirm("删除分类", "删除分类", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(() => {
        httpClient("xfDelType").post({type:id}).then((res) => {
          if (res.code == 0){
            this.tableData.splice(index,1)
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      })
    }
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>